import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {useIntl} from "react-intl";
import Api from "../../helper/Api";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useStore} from "../../store/useStore";
import {inputOnlyFloat, selectIconComponent} from "../../helper/Helper";
import {Box, Grid, Select} from "@material-ui/core";
import Spinner from "../spinner/Spinner";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";
import PropTypes from "prop-types";

const NormgroupValuesForm = forwardRef((props, ref) => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [mounted, setMounted] = useState(false);
    const [readyChecked, setReadyChecked] = useState(false);
    const [cancelToken] = useState(axios.CancelToken.source());

    const [loadingData, setLoadingData] = React.useState(true);
    const [loadingNormgroups, setLoadingNormgroups] = React.useState(true);
    const [loadingDimensions, setLoadingDimensions] = React.useState(true);

    const [normgroups, setNormgroups] = React.useState([]);
    const [dimensions, setDimensions] = React.useState([]);

    const [selectedDimension, setSelectedDimension] = React.useState('');

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!loadingNormgroups && !loadingDimensions) {
            setLoadingData(false);
        }
    }, [loadingDimensions, loadingNormgroups]);

    const getNormgroups = useCallback(() => {
        Api.getApi(
            'normgroups',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setNormgroups(res.data['hydra:member']);
                } else {
                    props.showError(GlobalTransIntl('error_no_normgroup', intl));
                }

                setLoadingNormgroups(false);
            },
            props.catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, intl, props, state.token]);

    const getDimensions = useCallback(() => {
        const filter = ApiFilterBuilder([
            {
                name: 'isHidden',
                value: false,
            }
        ]);

        Api.getApi(
            'dimensions' + filter,
            (res) => {
                if (res.data['hydra:member'].length) {
                    const filteredDimensions = res.data['hydra:member'].filter(dimension => Object.keys(props.procedureObject.dimensionTemplates).indexOf(dimension.id.toString()) !== -1);
                    setDimensions(filteredDimensions);

                    if (filteredDimensions.length) {
                        setSelectedDimension(filteredDimensions[0]['@id']);
                    }
                } else {
                    props.showError(GlobalTransIntl('error_no_dimension', intl));
                }

                setLoadingDimensions(false);
            },
            props.catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, intl, props, state.token]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            if (props.isDimensions && !props.procedureObject) {
                props.showError(GlobalTransIntl('error_no_assessment_id', intl));
            }

            if (!props.isDimensions) {
                setLoadingDimensions(false);
            } else {
                getDimensions();
            }

            getNormgroups();
        }
    }, [dispatch, intl, mounted, getDimensions, getNormgroups, props]);

    const getNormgroupValueByNormgroup = useCallback((normgroupId, value) => {
        const findNormgroupValue = props.normgroupValues.find(
            (item) => (
                (!props.isDimensions && item.normgroup === normgroupId) ||
                (
                    item.normgroup === normgroupId &&
                    item.dimension === selectedDimension
                )
            )
        );

        if (findNormgroupValue) {
            return findNormgroupValue[value] || '';
        }

        return '';
    }, [props.normgroupValues, selectedDimension, props.isDimensions]);

    const setNormgroupValueByNormgroup = useCallback((normgroupId, valueType, value) => {
        const existingValueIndex = props.normgroupValues.findIndex(
            item => (
                (!props.isDimensions && item.normgroup === normgroupId) ||
                (
                    item.normgroup === normgroupId &&
                    item.dimension === selectedDimension
                )
            )
        );

        if (existingValueIndex !== -1) {
            props.setNormgroupValues(prev => {
                const newNormgroupValues = [...prev];

                newNormgroupValues[existingValueIndex][valueType] = value;

                return newNormgroupValues;
            });
        } else {
            const newObject = {
                normgroup: normgroupId,
                [valueType]: value
            };

            if (props.isDimensions) {
                newObject.dimension = selectedDimension;
            }

            props.setNormgroupValues(prev => (
                [
                    ...prev,
                    newObject
                ]
            ));
        }
    }, [selectedDimension, props]);

    const calculateAvailableNormgroups = useCallback(() => {
        const availableNormgroups = [];

        normgroups.forEach(normgroup => {
            const foundNormgroupValues = props.normgroupValues.filter((item) => item.normgroup === normgroup['@id']);

            if (foundNormgroupValues.length === dimensions.length) {
                availableNormgroups.push(normgroup['@id']);
            }
        });

        return availableNormgroups;
    }, [dimensions, props.normgroupValues, normgroups]);

    useImperativeHandle(ref, () => ({
        calculateAvailableNormgroups: () => {
            return calculateAvailableNormgroups();
        }
    }));

    const isReady = useCallback(() => {
        return !loadingData && ((!props.isDimensions && normgroups.length > 0) || (dimensions.length > 0 && normgroups.length > 0));
    }, [dimensions.length, loadingData, normgroups.length, props.isDimensions]);

    useEffect(() => {
        if (!readyChecked && isReady()) {
            setReadyChecked(true);
            props.setReady(true);
        }
    }, [readyChecked, isReady, props]);

    return (
        <>
            <Spinner show={loadingData} rowClass={'p-5'}/>
            {
                isReady() &&
                <Box p={2}>
                    {
                        (props.isDimensions) &&
                        <Box mb={2}>
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={12} lg={6} xl={4}>
                                    <FormControl variant={'outlined'} id={'assessments-normgroups-dimension'}>
                                        <InputLabel>
                                            {GlobalTransIntl('dimension', intl)}
                                        </InputLabel>
                                        <Select
                                            labelId="assessments--normgroups-dimension-select"
                                            value={selectedDimension}
                                            onChange={(event) => {
                                                setSelectedDimension(event.target.value);
                                            }}
                                            label={GlobalTransIntl('dimension', intl)}
                                            IconComponent={selectIconComponent}
                                        >
                                            {
                                                dimensions.map((dimension) => (
                                                    <MenuItem
                                                        value={dimension['@id']}
                                                        key={dimension['@id']}
                                                    >
                                                        {dimension.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} lg={2}>{GlobalTransIntl('normgroup', intl)}</Grid>
                                <Grid item xs={4} lg={5}>{GlobalTransIntl('average', intl)}</Grid>
                                <Grid item xs={4} lg={5}>{GlobalTransIntl('standard_deviation', intl)}</Grid>
                            </Grid>
                        </Grid>
                        {
                            normgroups.map((normgroup) => (
                                <Grid item xs={12} key={normgroup['@id']}>
                                    <Grid container spacing={2} className={'align-items-center'}>
                                        <Grid item xs={4} lg={2}>
                                            {normgroup.name}
                                        </Grid>
                                        <Grid item xs={4} lg={5}>
                                            <TextField label={GlobalTransIntl('average', intl)}
                                                       id={'project-create--average'}
                                                       autoComplete={'off'}
                                                       variant="outlined"
                                                       onChange={(e) => {
                                                           inputOnlyFloat(e, () => setNormgroupValueByNormgroup(normgroup['@id'], 'average', e.target.value))

                                                       }}
                                                       value={getNormgroupValueByNormgroup(normgroup['@id'], 'average')}
                                            />
                                        </Grid>
                                        <Grid item xs={4} lg={5}>
                                            <TextField label={GlobalTransIntl('standard_deviation', intl)}
                                                       id={'project-create--standard-deviation'}
                                                       autoComplete={'off'}
                                                       variant="outlined"
                                                       onChange={(e) => {
                                                           inputOnlyFloat(e, () => setNormgroupValueByNormgroup(normgroup['@id'], 'standardDeviation', e.target.value))
                                                       }}
                                                       value={getNormgroupValueByNormgroup(normgroup['@id'], 'standardDeviation')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            }
        </>
    );
});

NormgroupValuesForm.propTypes = {
    catchErrors: PropTypes.func.isRequired,
    showError: PropTypes.func.isRequired,
    normgroupValues: PropTypes.array.isRequired,
    setNormgroupValues: PropTypes.func.isRequired,
    setReady: PropTypes.func.isRequired,
    procedureObject: PropTypes.object,
    isDimensions: PropTypes.bool,
}

NormgroupValuesForm.defaultProps = {
    isDimensions: false,
}

export default NormgroupValuesForm;